import omit from 'lodash/omit';
import Vue from 'vue';

import AnalyticsProvider from '@/enums/AnalyticsProvider';
import {
  fullStoryIdentify,
  fullStoryTrack,
} from '@/packages/FullStory';
import googleAnalyticsTrack from '@/packages/googleAnalyticsTrack';
import store from '@/store';

class Analytics {
  constructor(statsig) {
    this.statsig = statsig;
  }

  identify(user) {
    this.trackGA({
      event: 'identify',
      userId: user.uuid,
      emailHashSha1: user.email_hash_sha1,
    });

    store.dispatch('updateStatsigUser');

    fullStoryIdentify(user);

    Vue.prototype.$sentry.setUser({
      uuid: user.uuid,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
    });
  }

  track(payload, services = [
    AnalyticsProvider.GoogleAnalytics,
    AnalyticsProvider.FullStory,
    AnalyticsProvider.Statsig,
  ]) {
    const gaPayload = {
      event: 'ga_event',
      platform: 'cx-web',
      ...payload,
    };

    const metadata = omit(gaPayload, ['event', 'eventName']);

    if (services.includes(AnalyticsProvider.GoogleAnalytics)) {
      googleAnalyticsTrack(gaPayload);
    }

    if (services.includes(AnalyticsProvider.FullStory)) {
      fullStoryTrack(payload.eventName, metadata);
    }

    if (services.includes(AnalyticsProvider.Statsig)) {
      this.statsig().logEvent(payload.eventName, null, metadata);
    }
  }

  trackGA(payload) {
    this.track(payload, [AnalyticsProvider.GoogleAnalytics]);
  }

  trackSignIn(type, isSuccess = true) {
    this.track({
      eventName: 'user_sign_in',
      parent: 'auth',
      child: 'sign-in',
      event_status: isSuccess ? 'success' : 'failure',
      type,
    });

    if (isSuccess) {
      // legacy login success event
      this.trackGA({
        eventName: 'gx:identity:login:submit-success',
        type,
      });

      // GA4 event
      this.trackGA({
        event: 'login',
        userId: store.getters.getUser?.uuid || null,
        method: type,
      });
    }
  }

  trackSignInFailure(type) {
    this.trackSignIn(type, false);
  }

  trackRegister(type, isSuccess = true) {
    this.track({
      eventName: 'user_sign_up',
      parent: 'auth',
      child: 'sign-in',
      event_status: isSuccess ? 'success' : 'failure',
      type,
    });

    if (isSuccess) {
      // legacy register success event
      this.trackGA({
        eventName: 'gx:identity:register:submit-success',
        type: 'email_password',
      });

      // GA4 event
      this.trackGA({
        event: 'register',
        userId: store.getters.getUser?.uuid || null,
        method: type,
      });
    }
  }

  trackRegisterFailure(type) {
    this.trackRegister(type, false);
  }

  trackPageLoad() {
    this.track({
      parent: 'global',
      child: 'webpage',
      eventName: 'page_load',
    });
  }

  // eslint-disable-next-line class-methods-use-this
  trackUAT(uat) {
    googleAnalyticsTrack({ uat_id: uat });
  }
}

export default {
  install(app) {
    const globalProperties = app.config?.globalProperties || app.prototype;
    globalProperties.$analytics = new Analytics(() => globalProperties.$statsig);
  },
};
