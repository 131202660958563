/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty';
import Vue from 'vue';
import Vuex from 'vuex';

import useBrowserApiHelpers from '@/composables/use-browser-api-helpers';
import BookingExperience from '@/enums/BookingExperience';
import UserStatus from '@/enums/UserStatus';
import UserType from '@/enums/UserType';
import { getCookie } from '@/helpers/cookies';
import { isUuid } from '@/helpers/uuid';
import { getAppLocale } from '@/i18n';
import User from '@/models/User';
import { buildStatsigUser } from '@/packages/Statsig';
import authModal from '@/store/auth-modal';
import emailVerification from '@/store/email-verification';
import errors from '@/store/errors';
import geolocation from '@/store/geolocation';
import globalSearch from '@/store/global-search';
import hubspot from '@/store/hubspot';
import livechat from '@/store/livechat';
import passwordlessLogin from '@/store/passwordless-login';
import permissions from '@/store/permissions';
import petConcerns from '@/store/pet-concerns';
import pets from '@/store/pets';
import promoCodes from '@/store/promo-codes';
import questionnaire from '@/store/questionnaire';
import quote from '@/store/quote';
import reviews from '@/store/reviews';
import search from '@/store/search';
import socialite from '@/store/socialite';
import speciesTreated from '@/store/species-treated';
import subscriptionPlans from '@/store/subscription-plans';
import subscriptions from '@/store/subscriptions';
import wallets from '@/store/wallets';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: new User(),
    site: {
      currency: window.__VETSTER.currency,
      locale: getAppLocale(),
      localeBase: window.__VETSTER.locale_base,
    },
    fixedFooterBooking: false,
    localLinks: {},
    logoPath: '',
    deviceOS: '',
    deviceBrowser: '',
    devicePlatform: '',
    setLocalLinks: null,
    hidePricing: false,
    authLoaded: false,
    authLoading: false,
    isGlobalUser: false,
  },

  modules: {
    authModal,
    emailVerification,
    errors,
    geolocation,
    globalSearch,
    hubspot,
    livechat,
    passwordlessLogin,
    permissions,
    petConcerns,
    pets,
    promoCodes,
    questionnaire,
    quote,
    reviews,
    search,
    socialite,
    speciesTreated,
    subscriptions,
    subscriptionPlans,
    wallets,
  },

  mutations: {
    setLocalLinks(state, value) {
      state.localLinks = value;
    },
    setUser(state, user) {
      state.user = new User(user);
    },
    setFixedFooterBooking(state, fixedFooterBooking) {
      state.fixedFooterBooking = fixedFooterBooking;
    },
    setLogoPath(state, value) {
      state.logoPath = value;
    },
    setDeviceOS(state, value) {
      state.deviceOS = value;
    },
    setDeviceBrowser(state, value) {
      state.deviceBrowser = value;
    },
    setPlatformType(state, value) {
      state.devicePlatform = value;
    },
    setHidePricing(state, value) {
      state.hidePricing = value;
    },
    setAuthLoaded(state, loaded) {
      state.authLoaded = loaded;
    },
    setAuthLoading(state, loading) {
      state.authLoading = loading;
    },
    setIsGlobalUser(state, value) {
      state.isGlobalUser = value;
    },
    setXsrfToken(state, response) {
      state.$auth.defaultHeaders({ 'X-XSRF-TOKEN': response.headers.get('XSRF-TOKEN') });
    },
  },

  actions: {
    async fetchUser({ dispatch }) {
      return this.$app.$api.get('v1/profile').then(
        (response) => {
          dispatch('identify', response.data.data);
        },
        // eslint-disable-next-line handle-callback-err
        () => {},
      );
    },
    async checkAuthAndUser({ state, dispatch, commit }, delayForStoreInit = false) {
      // wait for all Vuex stores
      if (delayForStoreInit) {
        // eslint-disable-next-line no-underscore-dangle
        await this._vm.$nextTick();
      }

      if (await state.$auth.authenticate()) {
        await dispatch('setupUser');
      } else if (!(await dispatch('verifyMagicLogin'))) {
        dispatch('hubspot/load');

        try {
          const { localStorageAvailable } = useBrowserApiHelpers();
          if (localStorageAvailable()) {
            window.localStorage.removeItem('userId');
          }
        } catch {
          //
        }
      }

      commit('setAuthLoaded', true);
    },
    identify({ commit, getters }, payload) {
      commit('setUser', payload);

      try {
        const { localStorageAvailable } = useBrowserApiHelpers();
        if (localStorageAvailable()) {
          window.localStorage.setItem('userId', getters.getUser.uuid);
        }
      } catch {
        //
      }

      // Do not identify when vi_message present
      if (!getters.getUser.vi_message) {
        this.$app.$analytics.identify(getters.getUser);
      }
    },
    async setupUser({ commit, dispatch, getters }) {
      if (!getters.hasUser) {
        commit('setAuthLoading', true);
        await dispatch('fetchUser');
        dispatch('hubspot/setupToken');

        if (getters.isClient) {
          await dispatch('pets/fetchPets');
          await dispatch('wallets/setupWallets');
          await dispatch('promoCodes/setupPromoCodes');
          await dispatch('subscriptions/fetchSubscriptions');
        }

        commit('setAuthLoading', false);
      }
    },
    async updateStatsigUser({ dispatch, getters, rootGetters }) {
      dispatch('updateStatsigUserAsync', getters.isClient ? {} : {
        memberships: null,
        scopes: [],
      });

      if (getters.isClient) {
        await dispatch('permissions/fetchPermissions');
        await dispatch('subscriptions/fetchSubscriptions');

        const subs = rootGetters['subscriptions/getSubscriptions'];
        const memberships = !isEmpty(subs)
          ? subs.map((subscription) => subscription.name)
          : null;

        dispatch('updateStatsigUserAsync', {
          memberships,
          scopes: rootGetters['permissions/getPermissions'],
        });
      }
    },
    updateStatsigUserAsync({ getters }, payload) {
      this.$app.$statsig?.updateUserAsync(buildStatsigUser(getCookie('v-uatid'), {
        user: getters.getUser,
        ...payload,
      }));
    },
    async verifyMagicLogin({
      commit,
      dispatch,
      getters,
      state,
    }) {
      if (getters.requiresMagicLogin) {
        commit('setAuthLoading', true);

        try {
          await state.$auth.verifyMagicLogin({
            ...getters.magicParams,
            platform: 'WEB',
          });

          await dispatch('setupUser');

          return true;
        } catch (err) {
          //
        } finally {
          commit('setAuthLoading', false);
        }
      }

      return false;
    },
  },

  getters: {
    getLocalLinks(state) {
      return state.localLinks;
    },
    getSiteCountryCode(state) {
      return state.site.locale.split('-', 2)[1];
    },
    getSiteLocale(state) {
      return state.site.locale;
    },
    getSiteLocaleBase(state) {
      return state.site.localeBase;
    },
    getSiteCurrency(state) {
      return state.site.currency;
    },
    hasUser(state) {
      return !!(state.user && state.user.uuid);
    },
    getUser(state) {
      return state.user;
    },
    isEcommerceCapable(state) {
      return state.site.localeBase === 'en-us';
    },
    isPartner(state) {
      return state.user.type === UserType.Partner;
    },
    isClient(state) {
      return state.user.type === UserType.Client;
    },
    isGuest(state) {
      return !state.user.uuid;
    },
    isOnboarding(state) {
      return state.user.status === UserStatus.Onboarding;
    },
    hidePricing(state) {
      return state.user.booking_experience === BookingExperience.HiddenPricing || state.hidePricing;
    },
    getFixedFooterBooking(state) {
      return state.fixedFooterBooking;
    },
    getAuthLoaded(state) {
      return state.authLoaded;
    },
    getAuthLoading(state) {
      return state.authLoading;
    },
    isGlobalUser(state) {
      return state.isGlobalUser;
    },
    magicParams() {
      const params = new URLSearchParams(window.location.search);

      return {
        id: params.get('u'),
        code: params.get('ml'),
      };
    },
    requiresMagicLogin(state, getters) {
      return !state.hasUser
        && window.location.pathname.includes('/p/')
        && isUuid(getters.magicParams.id)
        && /^[a-f\d-]+$/.test(getters.magicParams.code);
    },
  },
});
