/* eslint-disable no-param-reassign */
import Deferred from '@/models/Deferred';

export default {
  namespaced: true,
  state: {
    authModalMode: null,
    forceLocale: null,
    header: null,
    headerDescription: null,
    hideRegistration: false,
    isBooking: false,
    promoCode: null,
    promoCodeReadonly: false,
    resetPasswordStep: null,
    willAuth: null,
  },
  getters: {
    authModalMode(state) {
      return state.authModalMode;
    },
    forceLocale(state) {
      return state.forceLocale;
    },
    getHeader(state) {
      return state.header;
    },
    getHeaderDescription(state) {
      return state.headerDescription;
    },
    isBooking(state) {
      return state.isBooking;
    },
    isRegistrationHidden(state) {
      return state.hideRegistration;
    },
    promoCode(state) {
      return state.promoCode;
    },
    promoCodeReadonly(state) {
      return state.promoCodeReadonly;
    },
    resetPasswordStep(state) {
      return state.resetPasswordStep;
    },
    willAuthPromise(state) {
      return state.willAuth && state.willAuth.promise;
    },
  },
  mutations: {
    setAuthModalMode(state, value) {
      state.authModalMode = value;
    },
    setForceLocale(state, value) {
      state.forceLocale = value;
    },
    setIsBooking(state, value) {
      state.isBooking = value;
    },
    setWillAuth(state, value) {
      state.willAuth = value;
    },
    awaitWillAuth(state, value) {
      if (state.willAuth) {
        state.willAuth.resolve(value);
      }
    },
    setHeader(state, value) {
      state.header = value;
    },
    setHeaderDescription(state, value) {
      state.headerDescription = value;
    },
    setPromoCode(state, value) {
      state.promoCode = value;
    },
    setPromoCodeReadonly(state, value) {
      state.promoCodeReadonly = !!value;
    },
    setResetPasswordStep(state, value) {
      state.resetPasswordStep = value;
    },
    setHideRegistration(state, value) {
      state.hideRegistration = value;
    },
  },
  actions: {
    setAuthModalMode({ commit, state }, payload = 'login') {
      commit('setAuthModalMode', payload);

      if (payload && !state.willAuth) {
        commit('setWillAuth', new Deferred());
      }
    },
    setPromoCode({ commit }, payload) {
      commit('setPromoCode', payload);
    },
    setPromoCodeReadonly({ commit }, payload) {
      commit('setPromoCodeReadonly', !!payload);
    },
    setForceLocale({ commit }, payload) {
      commit('setForceLocale', payload);
    },
    setIsBooking({ commit }, payload) {
      commit('setIsBooking', payload);
    },
    closeAuthModal({ commit }, success) {
      commit('setAuthModalMode', null);
      commit('setForceLocale', null);
      commit('setHeader', null);
      commit('setHeaderDescription', null);
      commit('setIsBooking', false);
      commit('setPromoCode', null);
      commit('setPromoCodeReadonly', false);
      commit('awaitWillAuth', success);
      commit('emailVerification/setVerificationData', null, { root: true });
    },
  },
};
