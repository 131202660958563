import every from 'lodash/every';

export default {
  namespaced: true,
  state: {
    geolocation: {
      city: null,
      province: null,
      country: null,
    },
    isFetched: false,
    promise: null,
  },
  getters: {
    defaultGeolocation() {
      return {
        city: window.__VETSTER.geo.city,
        province: window.__VETSTER.geo.region,
        country: window.__VETSTER.geo.country,
      };
    },
    getGeolocation(state) {
      return state.geolocation;
    },
  },
  actions: {
    async fetchGeolocation({ commit, getters, state }) {
      // Avoid any /p/geo request when Cloudflare HTTP headers already contain
      // a city location. In production, this will be the 90% case.
      if (every(getters.defaultGeolocation, Boolean)) {
        commit('setGeolocation', getters.defaultGeolocation);
        commit('setIsFetched');

        return null;
      }

      // Only request /p/geo once, previous request already completed.
      if (state.isFetched) {
        return null;
      }

      // Another /p/geo request is in-progress.
      if (state.promise) {
        return state.promise;
      }

      const promise = this.$app.$api.get('p/geo')
        .then((response) => {
          const payload = response.data.data;

          commit('setGeolocation', {
            city: payload.city,
            province: payload.region_code,
            country: payload.country_code,
          });
        })
        .catch(() => {})
        .finally(() => {
          commit('setIsFetched');
        });

      commit('setPromise', promise);

      return promise;
    },
  },
  mutations: {
    setGeolocation(state, geolocation) {
      state.geolocation = geolocation;
    },
    setIsFetched(state) {
      state.isFetched = true;
      state.promise = null;
    },
    setPromise(state, promise) {
      state.promise = promise;
    },
  },
};
