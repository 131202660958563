export default {
  methods: {
    applyScrollPadding(value) {
      const elementsToPad = document.querySelectorAll('[data-scroll-padding]');
      elementsToPad.forEach((el) => {
        const currentPaddingRight = parseInt(getComputedStyle(el).getPropertyValue('padding-right'), 10);
        el.style.paddingRight = value ? `${value + currentPaddingRight}px` : null;

        if (value) {
          el.style.transition = 'none';
        } else {
          setTimeout(() => {
            el.style.transition = null;
          }, 100);
        }
      });
    },
    disableScroll() {
      const { scrollY, innerWidth } = window;
      const scrollbarWidth = innerWidth - document.documentElement.clientWidth;
      this.applyScrollPadding(scrollbarWidth);

      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.overflow = 'hidden';
    },
    enableScroll() {
      const scrollY = document.body.style.top;
      this.applyScrollPadding(0);

      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflow = '';

      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    },
  },
};
