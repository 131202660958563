export default class QuestionnaireFleaAndTickStages {}

QuestionnaireFleaAndTickStages.Welcome = 'WELCOME';
QuestionnaireFleaAndTickStages.Location = 'LOCATION';
QuestionnaireFleaAndTickStages.Pet = 'PET';
QuestionnaireFleaAndTickStages.Question = 'QUESTION';
QuestionnaireFleaAndTickStages.Eligibility = 'ELIGIBILITY';

QuestionnaireFleaAndTickStages.getBaseStages = () => [
  QuestionnaireFleaAndTickStages.Welcome,
  QuestionnaireFleaAndTickStages.Location,
  QuestionnaireFleaAndTickStages.Pet,
  QuestionnaireFleaAndTickStages.Eligibility,
];
