<template>
  <vetster-input-group
    :id="getId"
    :label="isRebranded ? '' : label"
    :description-label="descriptionLabel"
    :shake="shake"
    :feedback="feedback || null"
    :error="error"
    :disabled="disabled"
  >
    <div
      class="relative"
      :class="{
        flex: addon,
        'mt-1': !noMargin,
      }"
    >
      <span
        v-if="addon"
        :class="stateClass"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-base"
        aria-hidden="true"
      >
        {{ addon }}
      </span>
      <input
        :id="getId"
        ref="input"
        :type="type"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :disabled="disabled"
        :max="max"
        :maxlength="maxlength"
        :min="min"
        :minlength="minlength"
        :placeholder="placeholder"
        :multiple="multiple"
        :name="name"
        :pattern="pattern"
        :readonly="readonly"
        :required="required"
        :value="currentValue"
        :class="currentClass"
        class="block w-full font-normal leading-5 border-0 p-3 ring-1 ring-inset transition"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        @keydown="onKeyDown"
        @keyup="$emit('keyup', $event)"
      >
      <label
        v-if="isRebranded"
        :for="getId"
        :class="[labelClass, {
          'text-xs leading-5 top-1 left-3': showLabel,
          'top-1/2 -translate-y-1/2 left-3': !showLabel,
        }]"
        class="absolute transition-all duration-300"
      >
        {{ label }}
      </label>
      <slot name="post-input" />
      <div
        v-if="error || reset"
        class="absolute inset-y-0 flex items-center right-0 pr-3"
      >
        <fa
          v-if="error"
          :icon="['fas', 'exclamation-circle']"
          class="text-base fa-fw text-red-600"
          aria-hidden="true"
        />
        <button
          v-if="reset"
          type="button"
          class="cursor-pointer w-3"
          :class="{ 'text-red-600 ml-1': error }"
          @click.prevent="onReset"
        >
          <fa :icon="['fal', 'times']" />
        </button>
      </div>
    </div>
  </vetster-input-group>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

import VetsterInputGroup from '@/brand-components/VetsterInputGroup';
import CommonAttributes from '@/mixins/CommonAttributes';
import CommonInputAttributes from '@/mixins/CommonInputAttributes';

library.add(faExclamationCircle);

export default {
  name: 'VetsterInput',

  components: { VetsterInputGroup },

  mixins: [
    CommonAttributes,
    CommonInputAttributes,
  ],

  props: {
    addon: {
      type: String,
      default: undefined,
    },
    isRebranded: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentClass() {
      return {
        ...this.stateClass,
        'pl-8': this.addon,
        'pr-12': this.error && this.reset,
        'pr-8': this.error || this.reset,
        'pt-7': this.isRebranded,
        'placeholder:text-neutral-700': this.isRebranded && this.showPlaceholder,
        'placeholder:!text-transparent': this.isRebranded && !this.showPlaceholder,
        'h-11 placeholder:text-base rounded-lg': this.isRebranded,
        'ring-red-600 text-red-600 focus:border-red-600 focus:ring-red-600 placeholder:text-red-600': this.isRebranded && this.error,
        'ring-neutral-200 focus:ring-ocean-600 text-neutral-900': this.isRebranded && !this.error,
        'text-base placeholder:text-neutral-500 ring-neutral-400 focus:ring-2 focus:ring-inset': !this.isRebranded,
      };
    },

    labelClass() {
      if (this.error) {
        return this.disabled ? 'text-red-600/40' : 'text-red-600';
      }

      return this.disabled ? 'text-neutral-700/40' : 'text-neutral-700';
    },

    showLabel() {
      return this.label && (this.currentValue || this.isFocused);
    },

    showPlaceholder() {
      return this.isFocused && !this.currentValue;
    },

    stateClass() {
      return {
        'opacity-40': this.disabled,
        'border-red-600 text-red-600 focus:border-red-600 focus:ring-red-600': this.error,
        'focus:ring-ocean-900 text-neutral-900': !this.error,
      };
    },
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },

    onReset() {
      this.currentValue = '';
      this.$nextTick(() => this.$emit('reset'));
    },
  },
};
</script>
