import { StatsigPlugin } from '@vetster/statsig-vue';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';

import usePvarsStorage from '@/composables/use-pvars-storage';
import { analyticsDefaultPayload } from '@/helpers/analytics';

const { latestPvars } = usePvarsStorage();

const addCustomIds = (payload, userIds) => {
  const customIDs = pickBy(userIds, Boolean);

  if (size(customIDs)) {
    payload.customIDs = customIDs;
  }

  return payload;
};

const getDeviceType = () => {
  if ('ontouchstart' in document.documentElement) {
    return 'MOBILE_WEB';
  }

  return 'DESKTOP_WEB';
};

const buildGuestUser = (uatId) => {
  const pvars = latestPvars();

  return addCustomIds({
    country: window.__VETSTER.geo.country,
    locale: window.__VETSTER.locale,
    userAgent: window.navigator.userAgent,
    custom: {
      platform: 'WEB',
      platform_device: getDeviceType(),
      type: 'anonymous', // deprecated
      city: window.__VETSTER.geo.city,
      region: window.__VETSTER.geo.region,
      ...pvars,
    },
  }, { uatId });
};

export function buildStatsigUser(uatId, { memberships, scopes, user }) {
  const { organization } = user;
  const pvars = latestPvars();

  return addCustomIds({
    userID: user.uuid,
    country: window.__VETSTER.geo.country,
    userAgent: window.navigator.userAgent,
    locale: user.locale,
    custom: {
      platform: 'WEB',
      platform_device: getDeviceType(),
      kind: 'user',
      type: user.isClient() ? 'client' : 'partner', // deprecated
      userType: user.type,
      city: window.__VETSTER.geo.city,
      region: window.__VETSTER.geo.region,
      email_hash: user.email_hash_sha1,
      language: user.locale === 'fr-CA' ? 'FR' : 'EN',
      memberships,
      organization: organization?.name,
      organizationUserStatus: organization?.organization_user?.status,
      organizationType: organization?.type,
      scopes,
      source: user.source,
      ...pvars,
    },
    privateAttributes: {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
    },
  }, {
    userUuid: user.uuid,
    uatId,
  });
}

export function initStatsig(Vue, uat) {
  Vue.use(StatsigPlugin, {
    clientId: import.meta.env.VITE_STATSIG_CLIENT_ID,
    clientOptions: {
      environment: {
        tier: import.meta.env.VITE_STATSIG_ENVIRONMENT,
      },
      networkConfig: {
        api: import.meta.env.VITE_STATSIG_API,
      },
    },
    evaluationCallback: (type, detail) => {
      window.FS?.event(`Statsig ${type} check`, detail);
    },
    logEventCallback: (name, value, metadata) => ({
      ...analyticsDefaultPayload(),
      ...metadata,
    }),
    user: buildGuestUser(uat),
  });
}
