export default class QuestionnaireStatus {}

QuestionnaireStatus.NotStarted = 'NOT_STARTED';
QuestionnaireStatus.InProgress = 'IN_PROGRESS';
QuestionnaireStatus.Completed = 'COMPLETED';

QuestionnaireStatus.Disqualified = 'DISQUALIFIED';
QuestionnaireStatus.Converted = 'CONVERTED';

QuestionnaireStatus.getActive = () => [
  this.InProgress,
  this.Completed,
];
