<template>
  <vetster-input-group
    :id="getId"
    :label="label"
    :shake="shake"
    :feedback="feedback || null"
    :error="error"
    :optional="optional"
    :optional-label="optionalLabel"
    :optional-label-class="optionalLabelClass"
    :is-private="isPrivate"
  >
    <div
      class="relative rounded-md shadow-sm"
      :class="{ flex: addon, 'mt-1': !!label }"
    >
      <span
        v-if="addon"
        :class="currentBaseClass"
        class="inline-flex items-center whitespace-nowrap px-3 rounded-l-md border border-r-0 border-neutral-300 bg-neutral-50 text-neutral-600"
      >
        {{ addon }}
      </span>
      <input
        :id="getId"
        ref="input"
        :aria-label="label"
        :type="type"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :disabled="disabled"
        :max="max"
        :maxlength="maxlength"
        :min="min"
        :minlength="minlength"
        :multiple="multiple"
        :name="name"
        :pattern="pattern"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
        :value="currentValue"
        :class="currentClass"
        class="block w-full border-neutral-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200/50 sm:leading-5 pr-6 transition"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @keyup="$emit('keyup', $event)"
        @keydown="onKeyDown"
      />
      <div
        v-if="error || icon"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <fa
          v-if="error"
          :icon="['fal', 'exclamation-circle']"
          class="fa-fe text-lg text-red-500"
        />
        <fa
          v-if="!error && icon"
          :icon="icon"
          class="fa-fe text-lg text-indigo-500"
        />
      </div>
    </div>
  </vetster-input-group>
</template>

<script>
import merge from 'lodash/merge';

import VetsterInputGroup from '@/components/VetsterInputGroup';
import CommonAttributes from '@/mixins/CommonAttributes';
import CommonInputAttributes from '@/mixins/CommonInputAttributes';

export default {
  name: 'VetsterInputLegacy',

  components: { VetsterInputGroup },

  mixins: [
    CommonAttributes,
    CommonInputAttributes,
  ],

  props: {
    icon: {
      type: Array,
      default: undefined,
    },
    addon: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'base',
    },
    optionalLabel: {
      type: String,
      default: null,
    },
    optionalLabelClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    currentBaseClass() {
      return {
        'cursor-not-allowed opacity-50': this.disabled,
        'sm:text-xl text-lg py-4': this.size === 'xl',
        'sm:text-sm': this.size !== 'xl',
        'border-red-300 text-red-800 placeholder-red-300 focus:border-red-300 focus:ring-red': this.error,
      };
    },

    currentClass() {
      return merge(this.currentBaseClass, {
        'flex-1 rounded-none rounded-r-md': this.addon,
      });
    },
  },
};
</script>
