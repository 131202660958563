import { DateTime } from 'luxon';

export default function useSmartTime() {
  const timezone = DateTime.local().zoneName;

  const timeslotDefault = {
    timeFrom: 800,
    timeTo: 2000,
  };

  const currentTime = DateTime.local().setZone(timezone);

  const dateTimeObject = (timestamp) => DateTime.fromISO(timestamp, {
    zone: timezone,
  });

  const isInPast = (timestamp) => DateTime.fromISO(timestamp).setZone(timezone).startOf('day')
    < DateTime.local().setZone(timezone).startOf('day');

  const skipToNextDay = (dateTime) => dateTime.hour >= 23
    || (dateTime.hour === 22 && dateTime.minute >= 30);

  const searchTimeFrom = (search = {}) => {
    if (search?.date_from) {
      if (dateTimeObject(search.date_from).setZone(timezone) > currentTime) {
        return search.time_from || timeslotDefault.timeFrom;
      }
    }

    if (skipToNextDay(currentTime)) {
      return timeslotDefault.timeFrom;
    }

    if (currentTime.minute > 29) {
      return parseFloat(`${currentTime.hour + 1}00`);
    }

    return parseFloat(`${currentTime.hour}30`);
  };

  const searchTimeTo = (search = {}) => {
    if (search && search.date_from) {
      if (dateTimeObject(search.date_from).setZone(timezone) > currentTime) {
        return search.time_to || timeslotDefault.time;
      }
    }

    if (skipToNextDay(currentTime) || currentTime.hour < 19) {
      return timeslotDefault.timeTo;
    }

    return 2359;
  };

  const validDateFrom = (date) => {
    let dateFrom;

    if (date) {
      dateFrom = dateTimeObject(date);
    } else {
      dateFrom = DateTime.local().setZone(timezone);
    }

    if (isInPast(dateFrom)) {
      dateFrom = DateTime.local().setZone(timezone);
    }

    if (skipToNextDay(dateFrom)) {
      return dateFrom.plus({ days: 1 }).toISODate();
    }

    return dateFrom.toISODate();
  };

  const validDateTimeSearchData = (search = {}) => Object.assign(search, {
    date_from: validDateFrom(search.date_from),
    time_from: searchTimeFrom(search),
    time_to: searchTimeTo(search),
  });

  const parseHour24 = (timeString) => (timeString > 0
    ? parseFloat(timeString.substring(0, timeString.length - 2))
    : 0);

  const formatHour12 = (timeString) => (parseHour24(timeString) > 12
    ? parseHour24(timeString) - 12
    : parseHour24(timeString) || 12);

  const formatMinute = (timeString) => (
    timeString?.length >= 2
    && timeString.substring(timeString.length - 2, 4)
  ) || '00';

  const formatAmPm = (timeString) => (parseHour24(timeString) > 11 ? 'PM' : 'AM');

  const convertTo12HourTime = (time) => {
    if (time !== 0 && !time) {
      return '-';
    }

    const timeString = time?.toString();

    return `${formatHour12(timeString)}:${formatMinute(timeString)} ${formatAmPm(timeString)}`;
  };

  return {
    isInPast,
    skipToNextDay,
    searchTimeFrom,
    searchTimeTo,
    validDateFrom,
    validDateTimeSearchData,
    convertTo12HourTime,
  };
}
