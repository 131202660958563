import pick from 'lodash/pick';
import size from 'lodash/size';

import QuestionnaireFleaAndTickStages from '@/enums/QuestionnaireFleaAndTickStages';
import QuestionnaireFleaAndTickSteps from '@/enums/QuestionnaireFleaAndTickSteps';
import QuestionnaireStatus from '@/enums/QuestionnaireStatus';
import QuestionnaireType from '@/enums/QuestionnaireType';

export default {
  namespaced: true,

  state: {
    currentStage: null,
    currentQuestion: null,
    inputs: {
      city: null,
      country: null,
      province: null,
    },
    participation: null,
    pet: null,
    stages: null,
    steps: null,
    type: null,
    questionnaire: null,
  },

  getters: {
    getActiveStep(state) {
      return state.steps ? state.steps.indexOf(state.currentStage) + 1 : null;
    },
    getCurrentQuestion(state) {
      return state.currentQuestion;
    },
    getInputs(state) {
      return state.inputs;
    },
    getParticipation(state) {
      return state.participation;
    },
    getPet(state) {
      return state.pet;
    },
    getQuestionnaire(state) {
      return state.questionnaire;
    },
    getQuestionCount(state) {
      return state.questionnaire?.question_count || 3;
    },
    getStepCount(state) {
      return size(state.steps);
    },
    getType(state) {
      return state.type;
    },
    hasParticipation(state) {
      return size(state.participation);
    },
    isFleaAndTickType(state) {
      return state.type === QuestionnaireType.FleaAndTick;
    },
    isParticipationComplete(state) {
      return state.participation?.status === QuestionnaireStatus.Completed;
    },
    showHeaderBackButton(state) {
      return ![QuestionnaireFleaAndTickStages.Welcome, QuestionnaireFleaAndTickStages.Eligibility].includes(state.currentStage);
    },
  },

  mutations: {
    setCurrentStage(state, currentStage) {
      state.currentStage = currentStage;
    },
    setCurrentQuestion(state, currentQuestion) {
      state.currentQuestion = currentQuestion;
    },
    setInputs(state, data) {
      state.inputs = {
        ...state.inputs,
        ...pick(data, Object.keys(state.inputs)),
      };
    },
    setParticipation(state, participation) {
      state.participation = participation;
    },
    setPet(state, pet) {
      state.pet = pet;
    },
    setQuestionnaire(state, questionnaire) {
      state.questionnaire = questionnaire;
    },
    setStages(state, stages) {
      state.stages = stages;
    },
    setSteps(state, steps) {
      state.steps = steps;
    },
    setType(state, type) {
      state.type = type;
    },
  },

  actions: {
    async answerCurrentQuestion({ commit, getters }, payload) {
      const response = await this.$app.$api.post(`/v1/cx/questionnaires/${getters.getParticipation?.uuid}/questions/current`, payload);

      // Data will be empty when the questionnaire is finished
      commit('setCurrentQuestion', response.data?.data);

      return response;
    },

    async createParticipation({ commit, getters }, payload) {
      const response = await this.$app.$api.post(`/v1/cx/questionnaires/${getters.getQuestionnaire?.slug}/participate`, {
        pet_uuid: payload.pet_uuid,
      });

      commit('setParticipation', response.data.data);
    },

    async fetchCurrentQuestion({ commit, getters, dispatch }) {
      const response = await this.$app.$api.get(`/v1/cx/questionnaires/${getters.getParticipation?.uuid}/questions/current`);

      commit('setCurrentQuestion', response.data.data);
      dispatch('syncCurrentStageWithQuestion');
    },

    async fetchParticipation({ commit }, payload) {
      const response = await this.$app.$api.get(`v1/cx/questionnaires/${payload.participationUuid}`);
      const { data } = response.data;

      commit('setParticipation', data);

      return data;
    },

    async fetchPetActiveParticipation({ commit, dispatch }, payload = {}) {
      const questionnaire = await dispatch('fetchQuestionnaire', payload);

      // TODO: Gracefully handle when questionnaire is empty

      const activeParticipation = questionnaire?.active_participation;

      if (!activeParticipation) {
        commit('setParticipation', null);
      } else {
        // Fetch full participation data since active_participation lacks latest_result and questions
        await dispatch('fetchParticipation', { participationUuid: activeParticipation?.uuid });
      }
    },

    async fetchQuestionnaire({ commit, state }, payload = {}) {
      const response = await this.$app.$api.get('v1/cx/questionnaires', {
        params: {
          filter: {
            type: state.type,
            ...payload.filter,
          },
        },
      });

      const questionnaire = response.data.data;

      // Data will be empty when species is not supported
      if (size(questionnaire)) {
        commit('setQuestionnaire', questionnaire[0]);

        return questionnaire[0];
      }

      return questionnaire;
    },

    async fetchRecommendation({ getters }) {
      return this.$app.$api.post(`v1/cx/questionnaires/${getters.getParticipation?.uuid}/recommend`, getters.getInputs);
    },

    initialize({ commit }, payload) {
      commit('setCurrentStage', payload.stage);
      commit('setType', payload.type);
    },

    nextStage({ commit, state }) {
      const currentIndex = state.stages.indexOf(state.currentStage);

      if (currentIndex < state.stages.length - 1) {
        const nextStage = state.stages[currentIndex + 1];
        commit('setCurrentStage', nextStage);
      }
    },

    async previousQuestion({ commit, getters, dispatch }) {
      const response = await this.$app.$api.post(`/v1/cx/questionnaires/${getters.getParticipation?.uuid}/questions/previous`);

      commit('setCurrentQuestion', response.data.data);
      dispatch('syncCurrentStageWithQuestion');

      return response;
    },

    previousStage({ commit, state }) {
      const currentIndex = state.stages.indexOf(state.currentStage);

      if (currentIndex > 0) {
        const previousStage = state.stages[currentIndex - 1];
        commit('setCurrentStage', previousStage);
      }
    },

    setStages({ commit, getters }) {
      const baseStages = QuestionnaireFleaAndTickStages.getBaseStages();
      const questionStages = Array.from({ length: getters.getQuestionCount }, (_, index) => `${QuestionnaireFleaAndTickStages.Question}${index + 1}`);
      const stages = [...baseStages];
      stages.splice(stages.length - 1, 0, ...questionStages);
      commit('setStages', stages);
    },

    // Used for the stepper only
    setSteps({ commit, getters }) {
      const baseSteps = QuestionnaireFleaAndTickSteps.getBaseSteps();
      const questionSteps = Array.from({ length: getters.getQuestionCount }, (_, index) => `${QuestionnaireFleaAndTickSteps.Question}${index + 1}`);
      const steps = baseSteps.concat(questionSteps);
      commit('setSteps', steps);
    },

    syncCurrentStageWithQuestion({ commit, state }) {
      if (state.currentQuestion && state.participation?.questions && state.stages) {
        const questionIndex = state.participation.questions.findIndex(
          (q) => q.slug === state.currentQuestion.slug,
        );

        if (questionIndex === -1) {
          return;
        }

        const targetStage = `${QuestionnaireFleaAndTickStages.Question}${questionIndex + 1}`;

        if (state.stages.includes(targetStage) && state.currentStage !== targetStage) {
          commit('setCurrentStage', targetStage);
        }
      }
    },
  },
};
