import { computed, getCurrentInstance } from 'vue';

import {
  forgetCookie,
  getCookie,
} from '@/helpers/cookies';

export default function useCookies() {
  const root = getCurrentInstance().proxy.$root;

  const removeViMessage = () => {
    forgetCookie('v-i');
    document.location.reload();
  };

  const hasViMessage = computed(() => (
    getCookie('v-i')
      && root.$store.getters.getUser?.vi_message
  ));

  const getViMessage = computed(() => (
    root.$store.getters.getUser?.vi_message
  ));

  const removeVetsterCookies = () => {
    [
      'v-auth.user-last-auth',
      'v-checkout-condition',
      'v-checkout-payment-option',
      'v-checkout-promo',
      'v-exit-intent-uuid',
      'v-global',
      'v-i',
      'v-language',
      'v-locale',
      'v-pvars',
      'v-search-concern',
      'v-search-pet',
      'v-search-species-treated',
      'v-search-url',
      'v-subscription-cart',
      'v-uatid',
    ].map(forgetCookie);
    document.location.reload();
  };

  const openCookiePreferences = () => {
    if (typeof window.displayPreferenceModal === 'function') {
      window.displayPreferenceModal();
    } else {
      root.$toast('Unable to load cookie preferences at this time - please contact support for assistance.', {
        status: 'error',
        timeout: 20000,
      });
    }
  };

  return {
    removeViMessage,
    hasViMessage,
    getViMessage,
    openCookiePreferences,
    removeVetsterCookies,
  };
}
