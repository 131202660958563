import debounce from 'lodash/debounce';

import HouseAdLocation from '@/enums/HouseAdLocation';

export default {
  data() {
    return {
      /**
       * While the House Ad transition is happening, isReady is false. If we have the initial height of the House ads
       * we can use it to calculate the padding of the main content and adjust the navbar position. Once the transition
       * is done, we can use the House Ad actual height that is being calculated by the ResizeObserver in handleHouseAdShown.
       */
      houseAd: {
        [HouseAdLocation.NavbarTop]: {
          isReady: false,
          initialHeight: null,
          observer: null,
        },
        [HouseAdLocation.NavbarBottom]: {
          isReady: false,
          initialHeight: null,
          observer: null,
        },
        totalHeight: 0,
      },
    };
  },

  watch: {
    'houseAd.totalHeight': debounce(function adjustMainContentPadding() {
      this.adjustMainContentPadding();
    }),
  },

  created() {
    this.$bus.$on('house-ad-shown', this.handleHouseAdShown);
    this.$bus.$on('house-ad-dismissed', this.handleHouseAdDismissed);
  },

  beforeDestroy() {
    this.$bus.$off('house-ad-shown', this.handleHouseAdShown);
    this.$bus.$off('house-ad-dismissed', this.handleHouseAdDismissed);

    Object.keys(this.houseAd).forEach((location) => this.houseAd[location].observer?.disconnect());
  },

  methods: {
    adjustMainContentPadding() {
      const heights = {
        navbar: this.$refs.nav.offsetHeight,
        [HouseAdLocation.NavbarTop]: this.getHouseAdHeight(HouseAdLocation.NavbarTop),
        [HouseAdLocation.NavbarBottom]: this.getHouseAdHeight(HouseAdLocation.NavbarBottom),
      };

      // Calculate the total height of all the elements
      this.$layout.navHeight = Object.values(heights).reduce((acc, height) => acc + height, 0);
    },

    getHouseAdHeight(location) {
      return this.houseAd[location].isReady
        ? this.$refs.navContainer.querySelector(`[data-house-ad="${location}"]`)?.offsetHeight || 0
        : this.houseAd[location].initialHeight;
    },

    handleHouseAdResizing(target, location, initialHeight) {
      const houseAd = this.houseAd[location];

      if (!this.isHouseAdTransitioning(houseAd, target, initialHeight)) {
        houseAd.isReady = true;

        if (location === HouseAdLocation.NavbarTop) {
          this.$refs.nav.style.top = `${target.offsetHeight}px`;
        }

        this.adjustMainContentPadding();
        delete target.dataset.initialHeight;
      }
    },

    handleHouseAdDismissed(location, offsetHeight) {
      if (!this.isValidHouseAdLocation(location)) {
        return;
      }

      if (location === HouseAdLocation.NavbarTop) {
        this.$refs.nav.style.top = 0;
      }

      this.$layout.navHeight -= offsetHeight;

      this.houseAd[location].observer?.disconnect();
    },

    handleHouseAdShown(target, location, initialHeight = null) {
      if (!this.isValidHouseAdLocation(location)) {
        return;
      }

      // Save the initial height to be used in the navHeight calculation
      target.dataset.initialHeight = initialHeight;

      if (location === HouseAdLocation.NavbarTop) {
        // Hack to avoid white flickering when the navbar is transitioning
        this.$refs.navContainer.style.backgroundColor = getComputedStyle(target).getPropertyValue('background-color');

        if (initialHeight) {
          this.$refs.nav.style.top = `${initialHeight}px`;
        }
      }

      this.houseAd.totalHeight += initialHeight || 0;

      // If initial height is set we need to wait for the transition to finish
      this.houseAd[location].isReady = !initialHeight;
      this.houseAd[location].initialHeight = initialHeight;
      this.houseAd[location].observer = new ResizeObserver(() => this.handleHouseAdResizing(target, location, initialHeight));
      this.houseAd[location].observer.observe(target, { box: 'border-box' });

      this.$layout.calculateNavHeight();
    },

    isHouseAdTransitioning(houseAd, target, initialHeight) {
      return !houseAd.isReady && target.offsetHeight !== initialHeight;
    },

    isValidHouseAdLocation(location) {
      return Object.values(HouseAdLocation).includes(location);
    },
  },
};
