export default {
  namespaced: true,
  state: {
    isFetched: false,
    permissions: [],
    promise: null,
  },
  getters: {
    getPermissions(state) {
      return state.permissions;
    },
  },
  actions: {
    async fetchPermissions({ commit, state }) {
      if (state.isFetched) {
        return null;
      }

      if (state.promise) {
        return state.promise;
      }

      const promise = this.$app.$api.get('v1/permissions')
        .then((response) => commit('setPermissions', response.data.data.scopes))
        .catch(() => {})
        .finally(() => {
          commit('setIsFetched');
        });

      commit('setPromise', promise);

      return promise;
    },
  },
  mutations: {
    setIsFetched(state) {
      state.isFetched = true;
      state.promise = null;
    },
    setPermissions(state, data) {
      state.permissions = data;
    },
    setPromise(state, promise) {
      state.promise = promise;
    },
  },
};
