export default class QuestionnaireFleaAndTickSteps {}

QuestionnaireFleaAndTickSteps.Location = 'LOCATION';
QuestionnaireFleaAndTickSteps.Pet = 'PET';
QuestionnaireFleaAndTickSteps.Question = 'QUESTION';

QuestionnaireFleaAndTickSteps.getBaseSteps = () => [
  QuestionnaireFleaAndTickSteps.Location,
  QuestionnaireFleaAndTickSteps.Pet,
];
