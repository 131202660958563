import * as Sentry from '@sentry/vue';

import setGlobalProperty from '@/helpers/setGlobalProperty';

const dsn = import.meta.env.VITE_SENTRY_DSN;

const buildTraceOptions = () => {
  const tracesSampleRate = parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE);

  if (tracesSampleRate > 0) {
    return {
      integrations: [
        Sentry.browserTracingIntegration(),
      ],
      tracingOptions: {
        trackComponents: true,
      },
      tracesSampleRate,
    };
  }

  return {};
};

const initClient = ({ Vue }) => {
  Sentry.init({
    Vue,
    denyUrls: [
      /^https:\/\/vetster-com\.translate\.goog/,
    ],
    dsn,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    ignoreErrors: [
      // https://accounts.google.com/gsi/client exceptions
      /^[a-z]{1,2}$/i, // In-browser Google Translate `throw Error("ga")`, "jb", "z", etc.
      /gmo$/i, // Google Search app - `Can't find variable: gmo`
    ],
    logErrors: import.meta.env.VITE_SENTRY_LOG_ERRORS === 'true',
    release: import.meta.env.VITE_SENTRY_RELEASE,
    ...buildTraceOptions(),
  });
};

export default {
  install(Vue) {
    if (dsn) {
      initClient({ Vue });
    }

    setGlobalProperty(Vue, '$sentry', Sentry);
  },
};

const getSentryHeaders = () => {
  const headers = {};

  [
    'baggage',
    'sentry-trace',
  ].forEach((key) => {
    const value = document.querySelector(`meta[name="${key}"]`)?.content;

    if (value) {
      headers[key] = value;
    }
  });

  return headers;
};

// eslint-disable-next-line import/prefer-default-export
export { getSentryHeaders };
