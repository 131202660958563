import CodeLength from '@/enums/CodeLength';

export default {
  namespaced: true,

  state: {
    codeLength: CodeLength.Passwordless,
    isLoading: false,
  },

  getters: {
    getCodeLength(state) {
      return state.codeLength;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },

  mutations: {
    setCodeLength(state, value) {
      if (value > 0) {
        state.codeLength = value;
      }
    },
    setLoading(state, value) {
      state.isLoading = value;
    },
  },

  actions: {
    async verifyPasswordlessLoginEmail({ commit, rootState }, payload) {
      commit('setLoading', true);
      try {
        const response = await rootState.$auth.verifyPasswordlessLoginEmail({
          email: payload.email,
          channel: payload.channel,
        });

        commit('setCodeLength', response.data.data.code_length);
        commit('emailVerification/setVerificationData', response.data.data, { root: true });
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
