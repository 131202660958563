import { reactive, ref, watch } from 'vue';

import setGlobalProperty from '@/helpers/setGlobalProperty';

export default {
  install(app) {
    const navHeight = ref(0);

    const getNavElementHeight = (selector) => {
      const el = document.querySelector(selector);

      return el?.dataset?.initialHeight || el?.offsetHeight || 0;
    };

    const calculateNavHeight = () => {
      navHeight.value = [
        'nav[role="navigation"]',
        '[data-house-ad="navbar-top"]',
        '[data-house-ad="navbar-bottom"]',
      ].map((selector) => Number(getNavElementHeight(selector)))
        .reduce((total, height) => total + height, 0);
    };

    const updateNavbarAnchors = (value = null) => {
      Array.from(document.querySelectorAll('[data-navbar-anchor]')).forEach((el) => {
        el.style[el.getAttribute('data-navbar-anchor')] = `${value || navHeight.value}px`;
      });
    };

    setGlobalProperty(app, '$layout', reactive({
      calculateNavHeight,
      navHeight,
      shareModalOpen: false,
      stickyNavOpen: true,
      updateNavbarAnchors,
      calculateTop: (id) => document.getElementById(id).getBoundingClientRect().top
        + window.scrollY
        - (navHeight.value + (document.querySelector('#contents')?.offsetHeight || 0)),
      closeShareModal() {
        this.shareModalOpen = false;
      },
      closeStickyNav() {
        this.stickyNavOpen = false;
      },
      openStickyNav() {
        this.stickyNavOpen = true;
      },
      toggleStickyNav() {
        this.stickyNavOpen = !this.stickyNavOpen;
      },
      toggleShareModal() {
        this.shareModalOpen = !this.shareModalOpen;
      },
    }));

    watch(navHeight, updateNavbarAnchors);
  },
};
