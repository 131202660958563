import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import SubscriptionPlanType from '@/enums/SubscriptionPlanType';
import {
  calculateAppointmentsCount,
  getMembershipCoInsuranceAmount,
} from '@/helpers/subscriptions';
import { i18n } from '@/i18n';

export default {
  namespaced: true,
  state: {
    activeSubscriptions: [],
    isFetched: false,
    promise: null,
  },
  getters: {
    canPurchaseSubscriptionPlan(state, getters, rootState, rootGetters) {
      const localesEnabled = ['en-US', 'en-CA', 'fr-CA'];
      const user = rootGetters.getUser;

      return !getters.hasActiveSubscription
        && localesEnabled.includes(i18n.locale)
        && (
          (rootGetters.isGuest && !rootGetters.isGlobalUser)
          || (user.isClient()
            && !user.isGlobal()
            && !user.isFetch()
            && !user.isPetmeds()
            && localesEnabled.includes(user.locale))
        );
    },
    getCoinsurancePercentage(state, getters) {
      if (getters.hasIndividualSubscription && !isEmpty(getters.getIndividualSubscription?.promo_codes)) {
        return getMembershipCoInsuranceAmount(getters.getIndividualSubscription.promo_codes[0]);
      }

      return undefined;
    },
    getSubscriptions(state) {
      return state.activeSubscriptions;
    },
    getIndividualSubscription(state) {
      return state.activeSubscriptions.find((subscription) => [SubscriptionPlanType.Membership, SubscriptionPlanType.Wellness, SubscriptionPlanType.PlusChat].includes(subscription.plan.type));
    },
    getMembershipSubscription(state) {
      return state.activeSubscriptions.find((subscription) => subscription.plan.type === SubscriptionPlanType.Membership);
    },
    hasActiveSubscription(state, getters) {
      return size(getters.getSubscriptions) > 0;
    },
    hasIndividualSubscription(state, getters) {
      return size(getters.getIndividualSubscription) > 0;
    },
    hasMembershipSubscription(state, getters) {
      return size(getters.getMembershipSubscription) > 0;
    },
    remainingAppointmentsCount(state, getters) {
      return calculateAppointmentsCount(getters.getIndividualSubscription?.appointment_vouchers, 'balance');
    },
  },
  mutations: {
    setIsFetched(state) {
      state.isFetched = true;
      state.promise = null;
    },
    setPromise(state, promise) {
      state.promise = promise;
    },
    setSubscriptions(state, subs) {
      state.activeSubscriptions = subs;
    },
  },
  actions: {
    async fetchSubscriptions({ commit, state }) {
      if (state.isFetched) {
        return null;
      }

      if (state.promise) {
        return state.promise;
      }

      const promise = this.$app.$api.get('v1/cx/subscriptions', {
        params: {
          filter: {
            active: 1,
          },
        },
      }).then((response) => commit('setSubscriptions', response.data.data))
        .catch(() => {})
        .finally(() => {
          commit('setIsFetched');
        });

      commit('setPromise', promise);

      return promise;
    },
  },
};
